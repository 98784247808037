import dayjs from 'dayjs'
import useOpenInfo from '@/hooks/openInfo'
import { HKREG, MACREG, MAINREG, PASSPORTREG, TWNREG } from '@/consts'
import { ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { queryApi } from '@/graphql/query'

export const useNation = () => {
  // const store = useStore();
  // const { openInfo } = store.state;
  // const { idKind } = openInfo;
  const { openInfo } = useOpenInfo()
  const { idKind } = openInfo.value
  let nation = ''
  let nationalityText = ''
  let nationality = ''
  switch (idKind) {
    case 1:
      nationalityText = '中国'
      nationality = 'CHN'
      nation = '大陆'
      break
    case 2:
      nationalityText = '中国香港'
      nationality = 'HKG'
      nation = '香港永久居民'
      break
    case 3:
      nationalityText = '中国香港'
      nationality = 'HKG'
      nation = '香港居民'
      break
    case 4:
      nationalityText = '中国台湾'
      nationality = 'TWN'
      nation = '台湾永久居民'
      break
    case 5:
      nationalityText = '中国澳门'
      nationality = 'MAC'
      nation = '澳门永久居民'
      break
    case 6:
      nationalityText = ''
      nationality = ''
      nation = '护照'
      break
    default:
  }

  return {
    nationalityText,
    nationality,
    nation,
    idKind
  }
}

export const verifyIdNo = (idKind: number) => {
  let pattern = MAINREG
  switch (idKind) {
    case 1:
      pattern = MAINREG
      break
    case 2: // 香港
      pattern = HKREG
      break
    case 3: // 香港
      pattern = HKREG
      break
    case 4: // 台湾
      pattern = TWNREG
      break
    case 5: // 澳门
      pattern = MACREG
      break
    case 6: // 护照
      pattern = PASSPORTREG
      break
    default:
      break
  }
  return pattern
}

export const calcAge = (val: string) => {
  const nowDate = new Date()
  const oy = dayjs(val).year()
  const om = dayjs(val).month() + 1
  const ty = dayjs(nowDate).year()
  const tm = dayjs(nowDate).month() + 1
  const gm = (ty - oy) * 12 + (tm - om)
  if (gm < 216) {
    // 小于十八岁
    return false
  }
  return true
}
// 数据字典
export const useDict = () => {
  const dicResult: any = ref()
  const { onResult } = useQuery(queryApi.findDictOpenAccount, {
    params: {
      markList: ['MARITAL_STATUS', 'COMMON_SEX', 'AO_NATIONALITY', 'ACCOUNT_PERSON_CALL']
    }
  }, {
    fetchPolicy: 'no-cache'
  })
  onResult(res => {
    const { findDictOpenAccount } = res?.data
    dicResult.value = findDictOpenAccount.data
  })
  return { dicResult }
}

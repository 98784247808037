
import { Dialog, ImagePreview } from 'vant'
import { nanoid } from 'nanoid'
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
// import { areaList } from '@vant/area-data'
import { areaList } from '@/consts/index'
import { useToggle } from '@vant/use'
import dayjs from 'dayjs'
import { useNation, calcAge, verifyIdNo, useDict } from './hooks/index'
import useOpenInfo from '@/hooks/openInfo'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import { ImageType, BusinessType, Source, CameraOptions, OpenType } from '@/enum'
import uploadApi from '@/axios/upload'
import { getPictureAction, setApptitle, isInAppWebview } from '@/utils/native-app'
import { dataURLtoFile, unzip } from '@/utils/file'
import useQueryFileTemp from '@/hooks/useFileTemp'
import beforeUpload from '@/utils/before-upload'
import useMutationCommon from '@/hooks/useMutationCommon'
import stepPercentage from '@/utils/number'
import { queryFn } from '@/graphql'
import { Message } from '@/components/Message'

import { pinyin } from 'pinyin-pro'

export default defineComponent({
  setup () {
    // vuex store数据
    const {
      openType,
      openInfo,
      meta,
      title,
      step,
      saveOpenInfoOfVuex,
      saveCommonOfVuex
    } = useOpenInfo()
    const { nation, nationalityText, nationality, idKind } = useNation()
    // 缓存图片列表
    const tempImagesList = useQueryFileTemp({
      kindSource: openType.value
    })
    const router = useRouter()
    const year = dayjs().year() - 100
    const minDate = new Date(year, 0, 1)
    const maxDate = new Date(2060, 0, 1)
    const fileList: any = ref([])
    // 相机选项
    const actionsList = [
      { key: CameraOptions.CAMERA, name: '拍摄' },
      { key: CameraOptions.PHOTO, name: '相册' }
    ]
    // 上传文件
    const [fileActionState, fileActionToggle] = useToggle()
    const form: any = reactive({
      familyName: '',
      givenName: '',
      familyNameSpell: '',
      givenNameSpell: '',
      idNo: '',
      sex: '',
      birthday: '',
      birthPlace: '',
      nationality,
      nationalityText,
      idCardAddress: '',
      idCardValidDateEnd: '',
      maritalStatus: 1,
      maritalText: '',
      familyAddress: '',
      familyAddressPre: '',
      familyDetailAddress: '',
      certificateIssuingCountryText: '',
      certificateIssuingCountryIdx: '',
      certificateIssuingCountry: '',
      callValue: '',
      callText: '',
      callIdx: '',
      birthPlaceOther: '', // 选择其他
      familyAddressOther: ''
    })
    const checked = ref(openInfo.value?.idCardValidDateEnd === '长期')
    const addressChecked = openInfo.value?.familyAddress
      ? ref(openInfo.value?.familyAddress === openInfo.value?.idCardAddress)
      : ref(false)
    const patternSpell = /^[A-Z]|[\s]+$/
    // 称谓
    const [showCallPicker, showCallPickerToggle] = useToggle()
    // 签发国家picker
    const [showCountryPicker, showCountryPickerToggle] = useToggle()
    // 出生日期
    const [showDatePicker, showDatePickerToggle] = useToggle()
    // 有效期
    const [showValidity, showValidityToggle] = useToggle()
    // 婚姻状况
    const [showMaritalPicker, showMaritalPickerToggle] = useToggle()
    // 住宅地址
    const [showAddressPicker, showAddressPickerToggle] = useToggle()
    // 国籍
    const [showNationPicker, showNationPickerToggle] = useToggle()
    // 出生地
    const [showBirthAreaPicker, showBirthAreaPickerToggle] = useToggle()
    const onCallConfirm = (val: { text: string; value: string }, idx: number) => {
      form.callValue = val.value
      form.callText = val.text
      form.callIdx = String(idx)
      showCallPickerToggle(false)
    }
    const onIssueCountryConfirm = (val: { text: string; value: string }, idx: number) => {
      form.certificateIssuingCountry = val.value
      form.certificateIssuingCountryText = val.text
      form.certificateIssuingCountryIdx = String(idx)
      showCountryPickerToggle(false)
    }
    // 国际地区
    const nationalityOptions = [
      { text: '中国大陆', value: 'CHN' },
      { text: '中国香港', value: 'HKG' },
      { text: '中国台湾', value: 'TWN' },
      { text: '中国澳门', value: 'MAC' },
      { text: '其他', value: 'OTH' }
    ]
    const nowDate = new Date()
    const currentDate = ref(
      openInfo.value.birthday ? new Date(openInfo.value.birthday.replace(/-/g, ',')) : new Date()
    )
    const currentDate2 = ref(
      openInfo.value.idCardValidDateEnd
        ? new Date(openInfo.value.idCardValidDateEnd.replace(/-/g, ','))
        : new Date()
    )

    const isHK = computed(() => openType.value === OpenType.OVERSEAS)
    const isFamilyAddress = computed(() => {
      if (isHK.value) {
        return true
      } else {
        return !addressChecked.value
      }
    })
    const pattern = verifyIdNo(idKind)
    // 查询数据字典
    const { dicResult } = useDict()
    // 国籍
    const nationOptions = computed(() =>
      dicResult.value?.AO_NATIONALITY.map((val: { name: string; value: string }) => ({
        text: val.name,
        value: val.value
      }))
    )
    // 性别列表处理
    const sexOptions = computed(() =>
      dicResult.value?.COMMON_SEX.filter((val: { value: string }) => val.value !== '2').map(
        (val: { name: string; value: string }) => ({
          text: val.name,
          name: val.value
        })
      )
    )
    // 婚姻列表
    const maritalOptions = computed(() =>
      dicResult.value?.MARITAL_STATUS.map((val: { name: string; value: number }) => ({
        text: val.name,
        value: Number(val.value)
      }))
    )
    // 称谓
    const callOptions = computed(() =>
      dicResult.value?.ACCOUNT_PERSON_CALL.map((val: { name: string; value: number }) => ({
        text: val.name,
        value: Number(val.value)
      }))
    )
    // 用户信息回填
    const initData = async () => {
      Object.keys(form).forEach(val => {
        const res = openInfo.value[val] ? openInfo.value[val] : form[val]
        form[val] = res
      })
      // 大陆身份证OCR拼音
      if (openType.value === 1) {
        form.familyNameSpell = pinyin(form.familyName, { toneType: 'none', type: 'array' }).join(
          ''
        )
        form.givenNameSpell = pinyin(form.givenName, { toneType: 'none', type: 'array' }).join('')
      }

      if (idKind !== 6) {
        form.nationality = nationality
        form.nationalityText = nationalityText
      }
    }

    // 判断是否在金马app内
    const isApp = computed(() => isInAppWebview())

    const uploadToApp = () => {
      if (!isApp.value || fileList.value.length) {
        return
      }
      fileActionToggle(true)
    }
    // 上传图片
    const uploadFile = (file: File) => {
      beforeUpload(file).then((res: any) => {
        const formatFile = new File([res], res.name, { type: res.type })
        fileList.value = [
          {
            url: '',
            status: 'uploading',
            message: '上传中...'
          }
        ]
        const params = {
          file: formatFile,
          imageType: ImageType.ACC_IMAGE_TYPE_501,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_1,
          kindSource: openType.value,
          idKindType: openInfo.value.idKind,
          source: Source.H5
        }
        uploadApi.uploadFile(params).then((res: any) => {
          const { openAccountUploadFile } = res
          if (openAccountUploadFile) {
            fileList.value = [
              {
                url: openAccountUploadFile.data,
                status: 'done'
              }
            ]
          } else {
            console.log('上传失败')
            Message({
              direction: 'vertical',
              text: openAccountUploadFile?.reason?.desc || '上传失败，请重试'
            })
          }
        })
      })
    }

    // 上传图片前钩子
    const beforeRead = (file: File) => {
      uploadFile(file)
    }

    const onFileSelect = async (item: { key: string }) => {
      const url = await getPictureAction(item.key)
      if (url) {
        const base64 = unzip(url)
        const imgUrl = `data:image/png;base64,${base64}`
        const png = dataURLtoFile(imgUrl, `${nanoid()}.png`)
        uploadFile(png)
      }
    }

    // 删除图片
    const fileDel = () => {
      fileList.value = []
    }

    const onDateConfirm = (date: Date) => {
      showDatePickerToggle(false)
      form.birthday = dayjs(date).format('YYYY-MM-DD')
    }
    const formatter = (type: string, val: string) => {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      }
      if (type === 'day') {
        return `${val}日`
      }
      return val
    }
    const formatterIdNo = (value: string) => {
      let value1 = value.replace(/（/g, '(')
      value1 = value1.replace(/）/g, ')')
      return value1
    }
    const formatterSpell = (value: string) => value.toUpperCase()

    const onAddressConfirm = (values: [{ name: string }]) => {
      showAddressPickerToggle(false)
      form.familyAddressPre = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/')
    }
    const onBirthAreaConfirm = (values: [{ name: string }]) => {
      showBirthAreaPickerToggle(false)
      form.birthPlace = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/')
    }
    const readonly = computed(() => form.nationalityText !== '其他')
    const showNation = () => {
      showNationPickerToggle(true)
    }
    // 确认国籍
    const onNationConfirm = (val: { text: string; value: string }) => {
      showNationPickerToggle(false)
      form.nationality = val.value
      form.nationalityText = val.text
    }
    const onValidityChange = (val: boolean) => {
      if (val) {
        form.idCardValidDateEnd = '长期'
      } else {
        form.idCardValidDateEnd = ''
      }
    }
    const onValidityConfirm = (date: Date) => {
      showValidityToggle(false)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      form.idCardValidDateEnd = `${year}-${month}-${day}`
    }
    // 选择婚姻状况
    const onMaritalConfirm = (val: { text: string; value: number }) => {
      showMaritalPickerToggle(false)
      form.maritalText = val.text
      form.maritalStatus = val.value
    }
    const onAddressChange = (val: boolean) => {
      addressChecked.value = val
      if (val) {
        form.familyAddress = form.idCardAddress
        form.familyAddressPre = ''
        form.familyDetailAddress = ''
        form.familyAddressOther = ''
      } else {
        form.familyAddress = form.familyAddressPre.replace(/\//g, '') + form.familyDetailAddress
      }
    }
    // 处理其他输入框
    const filterAddress = (number: number) => {
      const values = Object.values(areaList.province_list)
      if (number === 0) {
        values.forEach(item => {
          if (form.birthPlaceOther === item) {
            form.birthPlaceOther = ''
          }
        })
      } else {
        values.forEach(item => {
          if (form.familyAddressOther === item) {
            form.familyAddressOther = ''
          }
        })
      }
    }
    watch(
      form,
      () => {
        if (addressChecked.value && openType.value === 1) {
          form.familyAddress = form.idCardAddress
        }
      },
      {
        deep: true
      }
    )
    // 查询用户是否可以开户
    const queryBlack = () => {
      queryFn('customerBlackQuery', {
        idKind,
        idNo: form.idNo
      }).then(result => {
        const { data } = result
        if (data) {
          if (data.blackFlag) {
            Dialog.confirm({
              title: '验证未通过',
              message: '该身份证无法开户，请联系客服',
              cancelButtonText: '取消'
            })
              .then(() => {
                // on confirm
              })
              .catch(() => {
                // on cancel
              })
            return
          }
          if (data.isOpenIdCard) {
            Dialog.confirm({
              title: '验证未通过',
              message: '该身份证已开户，请更换',
              cancelButtonText: '取消'
            })
              .then(() => {
                // on confirm
              })
              .catch(() => {
                // on cancel
              })
            return
          }
          if (data.isExistedIdCard) {
            Dialog.confirm({
              title: '验证未通过',
              message: '该身份证已被使用，请更换',
              cancelButtonText: '取消'
            })
              .then(() => {
                // on confirm
              })
              .catch(() => {
                // on cancel
              })
            return
          }
          saveNext()
        }
      })
    }

    const isDisabled = computed(() => {
      let result =
        Object.values(form).every(item => item !== '') &&
        fileList.value.length &&
        fileList.value[0].status === 'done'
      const form1 = JSON.parse(JSON.stringify(form))
      if (form1.birthPlace !== '其他') {
        delete form1.birthPlaceOther
      }
      if (idKind === 1) {
        if (addressChecked.value) {
          delete form1.familyAddressOther
          delete form1.familyAddressPre
          delete form1.familyDetailAddress
        } else {
          if (form1.familyAddressPre !== '其他') {
            delete form1.familyAddressOther
          }
          delete form1.familyAddress
        }
        result = Object.values(form1).every(item => item !== '')
      } else {
        delete form1.idCardAddress
        delete form1.familyAddress
        if (form1.familyAddressPre !== '其他') {
          delete form1.familyAddressOther
        }
        result = Object.values(form1).every(item => item !== '')
      }
      if (isHK.value) {
        // 护照
        if (openInfo.value.idKind === 6) {
          delete form1.familyName
          delete form1.givenName
        }
        result = Object.values(form1).every(item => item !== '')
        console.log(form1, 'forms', result)
      }
      return !result
    })
    // 下一步
    const handleNext = () => {
      form.familyAddress = form.familyAddressPre.replace(/\//g, '') + form.familyDetailAddress
      if (form.familyAddressPre === '其他') {
        form.familyAddress = form.familyAddressOther + form.familyDetailAddress
      }
      console.log(form, 'form')
      if (!calcAge(form.birthday)) {
        Dialog.confirm({
          title: '出生日期',
          message: '小于18周岁人士不允许开户',
          cancelButtonText: '修改'
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
            document.querySelectorAll('.birthday')[0].scrollIntoView()
          })
        return
      }
      if (
        (form.nationalityText !== '中国' && idKind === 1) ||
        (form.nationalityText !== '中国香港' && (idKind === 2 || idKind === 3)) ||
        (form.nationalityText !== '中国台湾' && idKind === 4) ||
        (form.nationalityText !== '中国澳门' && idKind === 5)
      ) {
        Dialog.confirm({
          title: '国籍/地区',
          message: `您正在使用${nation}身份证，请确保国籍/地区选项为${nationalityText}`,
          cancelButtonText: '修改'
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          })
        return
      }

      Dialog.confirm({
        title: '信息保持一致',
        message: '请确认信息与身份证信息一致，否则可能导致开户失败',
        cancelButtonText: '修改'
      }).then(() => {
        queryBlack()
      })
    }
    // 监听缓存表图片变化
    watch(
      () => tempImagesList.value,
      () => {
        nextTick(() => {
          const png = tempImagesList.value?.filter(
            (val: { imageType: number; idKindType: number }) =>
              val.imageType === ImageType.ACC_IMAGE_TYPE_501 &&
              val.idKindType === openInfo.value.idKind
          )
          fileList.value = png.length ? [{ url: png[0].remoteUrl, status: 'done' }] : []
        })
      }
    )

    // 保存下一步调用接口
    const saveNext = () => {
      const percentage = stepPercentage(step)
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify(form)
      }
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      useSaveOpenInfo(params).then(() => {
        useMutationCommon(common)
        saveCommonOfVuex({ info: common })
        saveOpenInfoOfVuex({ info: form })
        router.push({ name: 'contact-info' })
      })
    }

    // 页面挂载完之后回填用户数据
    onMounted(() => {
      setApptitle(title)
      initData()
    })
    const viewExp = () => {
      ImagePreview({
        images: [require('../../assets/images/juzhuzhengming.png')],
        closeable: true
      })
    }
    return {
      viewExp,
      initData,
      form,
      sexOptions,
      idKind,
      pattern,
      patternSpell,
      nowDate,
      currentDate,
      currentDate2,
      formatter,
      minDate,
      maxDate,
      fileList,
      areaList,
      showNation,
      readonly,
      showNationPicker,
      showNationPickerToggle,
      nationalityOptions,
      onNationConfirm,
      maritalOptions,
      showDatePicker,
      showDatePickerToggle,
      onDateConfirm,
      onMaritalConfirm,
      showValidity,
      showValidityToggle,
      isHK,
      handleNext,
      isDisabled,
      showMaritalPicker,
      showMaritalPickerToggle,
      showAddressPicker,
      showAddressPickerToggle,
      showBirthAreaPicker,
      showBirthAreaPickerToggle,
      checked,
      onValidityChange,
      onValidityConfirm,
      addressChecked,
      onAddressChange,
      onAddressConfirm,
      onBirthAreaConfirm,
      showCountryPicker,
      showCountryPickerToggle,
      onIssueCountryConfirm,
      nationOptions,
      callOptions,
      showCallPicker,
      showCallPickerToggle,
      onCallConfirm,
      uploadToApp,
      fileActionToggle,
      uploadFile,
      beforeRead,
      onFileSelect,
      fileActionState,
      isApp,
      actionsList,
      formatterIdNo,
      fileDel,
      formatterSpell,
      isFamilyAddress,
      filterAddress
    }
  }
})
